<template>
  <b-sidebar
    id="sidebar-create-edit"
    ref="sidebar-create-edit"
    bg-variant="white"
    right
    backdrop
    shadow
    :title="
      this.dataModules.id == null ? 'Cadastrar campanha' : 'Editar campanha'
    "
  >
    <div>
      <!-- Name -->
      <b-form-group label="* Nome:" label-for="i-nome" class="pl-1 pr-1">
        <b-form-input
          id="i-nome"
          type="text"
          placeholder="Ex: Prospera"
          v-model="dataModules.nome"
        />
      </b-form-group>

      <b-form-group
        label="* Data de inicio:"
        label-for="i-nome"
        class="pl-1 pr-1"
      >
        <flat-pickr
          v-model="dataModules.data_inicio"
          :config="{ enableTime: false, dateFormat: 'd/m/Y' }"
          class="form-control"
        />
      </b-form-group>

      <b-form-group
        label="* Data de final:"
        label-for="i-nome"
        class="pl-1 pr-1"
      >
        <flat-pickr
          v-model="dataModules.data_final"
          :config="{ enableTime: false, dateFormat: 'd/m/Y' }"
          class="form-control"
        />
      </b-form-group>

      <b-form-group
        label="Campanha Ativa:"
        label-for="i-nota"
        class="pl-1 pr-1"
      >
        <b-form-checkbox
          checked="true"
          class="custom-control-primary"
          name="check-button"
          switch
          v-model="dataModules.status"
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </b-form-group>
      <b-row class="ml-1 mr-1 pb-1">
        <b-button variant="relief-primary" block @click="storeAndUpdate">
          {{ this.dataModules.id == null ? "Salvar" : "Editar" }}
        </b-button>
      </b-row>
      <b-row class="ml-1 mr-1 pb-1">
        <b-button variant="relief-danger" block @click="initValues">
          Limpar
        </b-button>
      </b-row>
    </div>
  </b-sidebar>
</template>
<script>
/* eslint-disable */
import { VBToggle } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import moment from 'moment';

export default {
  props: {
    dataProp: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    mapActions,
    mapState,
    flatPickr,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      // Variáveis Global

      dataModules: {
        nome: null,
        data_inicio: null,
        data_final: null,
        status: null,
        id: null,
        pathAPI: "campanhas",
      },
    };
  },
  computed: {
    ...mapState("connectAPI", ["modules"]),
  },
  methods: {
    ...mapState("connectAPI", ["store", "update"]),

    initValues() {
      this.dataModules.id = null;
      this.dataModules.nome = null;
      this.dataModules.data_inicio = null;
      this.dataModules.data_final = null;
      this.dataModules.status = null;
    },
  // moment(this.delivery_date, 'DD/MM/YYYY').format('YYYY-MM-DD 00:00:00'),
    storeAndUpdate() {
      if (this.dataModules.id == null) {
        if (this.dataModules.status == true) {
          this.dataModules.status = 1;
        } else {
          this.dataModules.status = 0;
        }
        this.dataModules.data_inicio = moment(this.dataModules.data_inicio, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        this.dataModules.data_final = moment(this.dataModules.data_final, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        this.$store
          .dispatch("connectAPI/store", {
            data: this.dataModules,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Cadastrado com sucesso!",
                icon: "ThumbsUpIcon",
                text: "Operação executada com sucesso.",
                variant: "success",
              },
            });
            this.initValues();
          })
          .finally(() => {
            this.$emit("fetch-all");
            this.closeSidebar();
          });
      } else {
        this.$store
          .dispatch("connectAPI/update", {
            data: this.dataModules,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Atualizado com sucesso!",
                icon: "ThumbsUpIcon",
                text: "Operação executada com sucesso.",
                variant: "info",
              },
            });
            this.initValues();
          })
          .finally(() => {
            this.$refs["sidebar-create-edit"].hide();
            this.$emit("fetch-all");
          });
      }
    },

    closeSidebar() {
      this.$refs["sidebar-create-edit"].hide();
    },
  },
  watch: {
    dataProp: {
      immediate: true,
      handler(val, oldVal) {
        if (!val) return;
        if (this.dataProp.id == null) {
          this.initValues();
        } else {
          this.dataModules.id = this.dataProp.id;
          this.dataModules.nome = this.dataProp.nome;
          this.dataModules.data_inicio = this.dataProp.data_inicio;
          this.dataModules.data_final = this.dataProp.data_final;
          if (this.dataProp.status == 1) {
            this.dataModules.status = true;
          } else {
            this.dataModules.status = false;
          }
        }
      },
    },
  },
  created() {},
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>